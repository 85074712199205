.page-not-found-page{
    text-align: center;
    position: relative;
    top:200px;
    color: black;
}
.acces-denied-page{
    text-align: center;
    position: relative;
    top: 200px;
    color: black;
}
.acces-denied-head{
    color: red;
}