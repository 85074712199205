@import "variables";
@import "mixins";

.setting-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0px !important;

    .setting-card-body {
        text-align: left;
    }
}

.sb-setting-card {
    margin: 10px 0px;
    padding: 5px 15px;
    width: 100% !important;
    justify-content: center;
    align-items: center;
    border-radius: 0rem;
    border: none !important;
    transition: transform 0.2s;
    // box-shadow: rgba(4, 169, 109, 0.133) 0px 5px 20px 0px;
    background: #fff;
    /* box-shadow: rgba(198, 176, 255, 1) 0px 12px 20px, rgba(23, 0, 102, 0.2) 4px 4px 20px, rgba(0, 0, 0, 0.1) 0px 0px 20px; */
}
.sb-setting-card:hover {
    transform: scale(1.01);
    box-shadow: rgba(4, 169, 109, 0.318) 0px 5px 20px 0px;
}

.sb-setting-card img {
    width: 40px;
    height: 40px;
}
.setting-page-header {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-shadow: rgba(201, 203, 202, 0.318) 0px 1px 0px 0px;
    
    .input-div{
position: relative;
.search-icon {
        position: absolute;
        right: 3%;
        top: 30%;
    }
    }
    
}
.setting-input {
    // width: 30%;
}

