@import "variables";
@import "mixins";

.user-activity-n-device-table{
    @include tableProperty;
}

.user-activity-location-device {
    padding: 10px;
    position: relative;
    margin: 0px !important;
    background-color: $whiteColor;
    height: calc(100vh - 180px);
}

.device-info-part {
    background-color: $whiteColor;
    box-shadow: 0 0 3px 1px gray;
    padding: 10px;
    min-height: auto;
}

.device-info-head {
    border-bottom: 1px solid gray;
}

.device-info-heading {
    font-size: $fz16;
    font-weight: 600;
    color: gray;
    text-align: center;
}

.mobile-device-img-part {
    text-align: center;
    padding: 5px;
    margin-top: 5px;
}

.android-mobile-img {
    width: 30%;
}

.device-info-table {
    margin-top: 10px;
    .device-info-table-data tr th {
        background-color: #f0f0f0 !important;
    }
}

.device-info-table-data{
    th,td {
        font-size: $fz12 !important;
        padding: 2px 5px !important;
    }
    tr:last-child th {
        border-bottom: none !important;
    }
    tr:last-child td {
        border-bottom: none !important;
    }
    tr:first-child {
        border-top: 1px dotted gray;
    }
    tr:last-child {
        border-bottom: 1px dotted gray;
    }
} 

.user-activity-thead {
    @include tableHeadProperty;
}

.user-activity-data-scroll {
    height: calc(100vh - 200px);
    // height: 365px !important;
    overflow: auto;
}

/* SCROLL BAR CSS with Styling START */
/* width */
.user-activity-data-scroll::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
.user-activity-data-scroll::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor;
}

/* Handle */
.user-activity-data-scroll::-webkit-scrollbar-thumb {
    background: $scrollerThumbColor;
    border-radius: 0px;
}

/* Handle on hover */
.user-activity-data-scroll:-webkit-scrollbar-thumb:hover {
    background: rgb(102, 102, 102);
}

/* SCROLL BAR CSS with Styling END */