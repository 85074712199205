@import "variables";
@import "mixins";

.attendance-table {
    @include tableProperty;
}

.attendance-monthly-scroll,
.attendance-daily-scroll {
    height: calc(100vh - 200px);
    margin: 10px;
}

.attendance-list-thead {
    @include tableHeadProperty;
}

.page-title.atten-fullday {
    text-align: left;
}

  .leave-type-request {
      color: $statusOrange !important;
  }

  .leave-type-approved {
      color: $statusGreen !important;
  }

.attendance-monthly-scroll{
    .attendance-list-row-data{
        td {
            vertical-align: top;
            padding-right: 10px !important;
            white-space: pre;
        } 
        td:not(:first-child) {
            color: $navLinkColor;
            font-size: $fz13;
        }
      
    }  
}
.attendance-list-name .table-data-attendance-imgages {
    height: 40px;
    margin-right: 10px;
 }
.attendance-daily-scroll .attendance-list-row-data td {
    vertical-align: top;
    padding-right: 10px !important;
}

.login-details .login-time-status,
.logout-time-status {
    color: green;
    margin-bottom: 3px;
    margin-top: 0px;
}

.login-time-location, .logout-time-location{
    p {
        color: green;
        padding: 3px 0px;
        margin-bottom: 0px;
    }
    i {
        // color: $nav_link_Textcolor;
        color: $darkOrangeColor;
        padding-right: 5px;
    }
} 

/* SCROLL BAR CSS with Styling START */
/* width */
.attendance-monthly-scroll,
.attendance-daily-scroll{
    &::-webkit-scrollbar {
        height: 5px;
        width: 5px;
    }
}

/* Track */
.attendance-monthly-scroll,
.attendance-daily-scroll{
    &::-webkit-scrollbar-track {
        background: $scrollerBackgroundColor;
    }
}

/* Handle */
.attendance-monthly-scroll,
.attendance-daily-scroll{
    &::-webkit-scrollbar-thumb {
        background: $scrollerThumbColor;
        border-radius: 0px;
    }
}

/* Handle on hover */
.attendance-monthly-scroll,
.attendance-daily-scroll{
    &:-webkit-scrollbar-thumb:hover {
        background: rgb(102, 102, 102);
    }
}

/* SCROLL BAR CSS with Styling END */