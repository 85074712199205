@import "variables";
@import "mixins";

.main_model {
    background-color: #b8b8b896;
    padding: 10px;
    z-index: 9999;
}

.model-add-user-body {
    padding: 20px 10px;
}

.add-user-heading {
    @include modelHeader;
}
.input-address-location{
    position: relative;
}
.add-user-input, .select-add-user-model, .model-select{
    border-radius: 5px;
    font-size: $fz13;
    background-color: $inputFieldback_color;
}
.country-code{
.react-select-container-list{
    min-width: 65px !important;
    margin: 0px;
    .react-select-list__control {
        height: 33px !important;
    }
}
}
.model-select{
    margin: 0 !important;
    width: 100% !important;
    .react-select-list__control {
        padding: 3px 10px 5px 8px !important;
        height: 34px !important;
        .react-select-list__indicator {
            padding: 2px 5px !important;
            position: absolute;
            right: 0px;
            top: 4px;
        }
    }
}
.input-address-location .bi-geo-alt{
    position: absolute;
    font-weight: 600 !important;
    left: 225px;
    bottom: 7px;    
}

.google-maps-model .modal-dialog{
    max-width: 850px;    
}


.img-box {
    text-align: center;
    padding: 5px;
    background: $whiteColor;
    border: 1px solid #919191a6;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    min-height: 235px;
    position: relative;
}

.img-file-name {
    position: relative;   
    text-align: center; 
    bottom: 0 !important;   
    font-size: $fz11; 
    color: $darkgrayColor;
}

.add-user-image {
    width: 125px;
    height: 135px;
    background-color: azure;
    border-radius: 20px;
    position: relative;
    top: 20px;
}

.btn-add-user-img {
    background-color: $greenBlueColor;
    color: $whiteColor;
    font-size: $fz14;
    position: absolute;
    bottom: 15px;
    left: 12px;
    text-align: center;
    padding: 5px 8px;
    width: auto;
    height: 35px;
    &:hover {
        background-color: $greenBlueColor;
        color: $whiteColor;
    }
    h6 {
        font-size: $fz13 !important;
        padding-left: 5px;
    }
}


.btn-inline-items {
    display: flex;
    position: relative;
    align-items: center;    
}
#countryCodeSelect{
    padding: 6px 2px;
    padding-inline: 4px;
    background-position: right 3px center;
}

.select-add-user-model {
    color: $blackColor;
}

.input-mobile-field .country-code {
    width: 75px;
    margin-right: 8px
}

.add-user .btn,
.add-user .form-control {
    box-shadow: none;
}

.add-user .form-control:focus {
    border: 1px solid #b1b1b1;
}

.enable-radio-client {
    position: relative;
    vertical-align: middle;
    align-items: center;
}

.enable-radio-status {
    margin-left: 10px;
}

.google-address-ok{
    position: absolute;
    right: 40px;
    background-color: green;
    color: white;
    border: none;
    padding: 3px 10px;
    &:hover{
        background-color: darkgreen;
    }
}

 //// MULTIPLE SELECTOR CSS START ///////
.multiSelectContainer li {
    padding: 5px 10px !important;
    color: $blackColor !important;
}
.multiSelectContainer li:hover {
    background-color: $lightGray !important;
    color: $blackColor !important;
}

.select-add-user-model {
    &.zone{
        .searchWrapper{
        display: flex;
        flex-wrap: nowrap;
        }
    }
    .searchWrapper {
        padding: 5px !important; 
        border-radius: 5px !important;
        display: flex;
        flex-wrap: wrap;        
        .searchBox {
            margin-top: 0px !important;
        }
    }    
    .optionContainer {
        border: "1px solid blue";
        border-radius: 0px !important;
        height: 130px;
    }  
    .chip{
        background: $greenBlueColor;
        line-height: 16px !important;
        padding: 2px 5px !important;
        border-radius: 2px !important;
        margin-bottom: 2px !important;
        margin-right: 5px !important;
    }   
}


//// MULTIPLE SELECTOR CSS END ///////


