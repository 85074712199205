@import "variables";
@import "mixins";

.closemenu {
    color: $topbarColor;
    position: relative;
    right: 0;
    left: 200px;
    z-index: 999;
    font-size: $fz18 !important;
    top: 0;
    transition: all 0.35s ease;
    transform: translateX(0);
    cursor: pointer;
}

.topbar-nav .toggle-shift-left {
    position: relative;
    left: 55px;
    z-index: 9;
}

.topbar-nav .dropdown-toggle {
    background-color: transparent;
    border: none;
    position: relative;
    top: 0;
    right: 0;
    color: $whiteColor;
    display: inline-flex;
    // width: 235px;
    box-shadow: none;
    margin-right: 30px;
}

.topbar-nav .dropdown-toggle:hover {
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.custom-sb-drop {
    position: absolute; inset: 0px 0px auto auto; margin: 0px; transform: translate(0px, 64px);
}

.dropdown-toggle::after {
    content: "";
    width: 30px;
    height: 30px;
    background: url("../../public/images/Topbar/down-caret.png");
    background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: contain !important;
    font-size: $fz30;
    // background-color: red;
    position: absolute;
    top: 15px;
    right: -20px;
    border-top: 0 solid #1B1E24;
        border-right: 0 solid transparent;
        border-bottom: 0;
        border-left: 0 solid transparent;
}

.dropdown-menu.show {
    display: block;
    // margin-left: 35px !important;
    // margin-top: 25px !important;
    // box-shadow: 0px 0px 2px 2px rgb(0 0 0 / 20%) !important;
    // border-top-style: hidden;
}

.logout-text {
    color: rgb(122, 2, 2);
    font-weight: 600;
}
.dropdown-item:hover {
    color: $whiteColor;
    background-color: $greenBlueColor;
}

.dropdown-item:focus {
    color: $whiteColor;
    background-color: $greenBlueColor;
}

.topbar {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    // height: 52px;
    height: 63px;
    background: #fff;
    z-index: 9;
    // box-shadow: 2px 5px 11px #f1f1f1;
}

.navbar-custom {
    position: relative;
    left: 0;
    top: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: 63px;
}

.logo-img {
    border-radius: 50%;
    // border: 3px solid #21ab79;
}

.list-unstyled {
    padding: 5px;
    height: 63px;
    display: flex;
    align-items: center;
}

.sb-user-name {
        text-align: left;
        font-weight: 700;
        color: $topbarColor;
        padding-top: 5px;
        font-size: 16px;
}

.sb-company-name {
    font-weight: 500;
}

.topbar-nav {
    .button-menu-mobile {
        background-color: transparent;
        box-shadow: none;
        border: none;
        outline: none;
        &:hover,
        &:focus {
            box-shadow: none;
            outline: none;
        }
    }
}

.your-days-left {
    position: relative;
    top: 13px;
    right: 130px;
    color: $whiteColor !important;
    animation-name: moving;
    animation-duration: 8s;
    animation-iteration-count: 4;
    animation-direction: alternate;
    opacity: 1;

    .go-to-accounts {
        text-decoration: none;
        color: $whiteColor !important;
    }

    .days-left-in-word {
        font-size: $fz14;
        color: red;

        b {
            font-size: $fz15;
        }

        i {
            padding-right: 5px;
        }
    }

    .days-left-in-number {
        font-size: $fz14;
        color: $darkOrangeColor;

        b {
            font-size: $fz15;
        }

        i {
            padding-right: 5px;
        }

        &.red {
            color: red;
        }
    }
}

@keyframes moving {
    0% {
        top: 13px;
        right: 130px;
    }

    100% {
        top: 13px;
        right: 350px;
    }
}