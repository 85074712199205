@import "variables";
@import "mixins";

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

.row {
    margin-left: 0px;
    margin-right: 0px;
    padding: 0px;
}

body {
    min-height: 100%;
    padding: 0px;
    margin: 0px;
    background: #f5f5f5 $mainBody_backgroundImg left top repeat;
    position: relative;
    font-size: $fz13;
    color: $topbarColor;
    overflow-x: hidden;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout {
    position: fixed !important;
    box-shadow: 2px 2px 2px #eee !important;
}

.pro-sidebar>.pro-sidebar-inner {
    background-color: transparent;
}

.page-wrapper {
    .main-body {
        @include mainBody;
        transition: all 0.35s ease;
        transform: translateX(0);

        &.toggle-shift-left {
            @include toggleShiftLeft;
            transition: all 0.35s ease;
            transform: translateX(0);
        }
    }
}

.page-content {
    @include pageContentProperty;
}

.page-title {
    @include pageTitle;
}

.seleting-menus {
    @include selectingMenus;
    z-index: 1;

    .page-top-bar-dropdowns {
        display: flex;
        float: right;
        align-items: center;

        .select {
            @include SelectDropdowns;
        }

        &:focus {
            box-shadow: none;
            border: 1px solid #c2c2c2;
        }
    }
}

table {
    border-color: #ced0d1 !important;
    margin-bottom: 0px !important;
}

.table> :not(:first-child) {
    border-top: none;
}

tbody th {
    padding: 5px !important;
}

tbody td {
    // background-color: $whiteColor !important;
    // background-color: $tablebody_backgroundColorG !important;
    padding: 5px 8px !important;
    // font-size: $fz13 !important;
    vertical-align: middle;
    opacity: 1;
    overflow-wrap: break-word;
}

tbody tr:nth-child(even) {
    background-color: $tablebody_backgroundColorG !important;
}
tbody tr:nth-child(odd) {
    background-color: $tablebody_backgroundColorY !important;
}

thead tr th:first-child {
    // padding-left: 15px !important;
    vertical-align: middle;
}

tbody tr td:first-child {
    // padding-left: 15px !important;
}

.form-select {
    padding: 0.26rem 2.25rem 0.38rem 0.75rem;
}

.btn-clear-select {
    @include buttonProperty($nav_back_Graycolor, 30px, 35px, 0, 16px, $whiteColor);
    align-items: center;
    text-align: center;
    padding: 3px;

    &:hover {
        background-color: $grayColor;
        box-shadow: none;
    }

    .bi-x-circle-fill {
        position: relative;
        color: $whiteColor;
    }
}

.btn-search-track {
    @include buttonProperty($grayColor, 30px, auto, 0, 14px, $whiteColor);
    // margin-right: 5px;
    margin-left: 5px;
    align-items: center;
    padding: 5px 10px;

    &:hover {
        color: $whiteColor;
        box-shadow: none;
        background-color: $blackColor;
    }

    &:focus {
        box-shadow: none;
    }

    h6 {
        font-size: $fz14;
        line-height: 0.9;
        padding-left: 5px;
    }
}

.btn-go-back {
    @include buttonProperty($blackColor, 30px, auto, 0, 15px, $whiteColor);
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;

    &:focus {
        box-shadow: none;
    }

    &:hover {
        color: $whiteColor;
    }

    h6 {
        font-size: $fz15;
        line-height: 0.9;
        padding-left: 6px;
    }
}

.download-and-pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 50px;
    padding: 6px;
    position: relative;
    bottom: 0;
    right: 0;
    .data-count-per-page{
        margin-right: 10px;
    }
}

.btn-download-excel {
    @include buttonProperty($greenBlueColor, auto, auto, 0, 14px, #FFF);

    // &:hover {
    //     box-shadow: none;
    //     background-color: $darkOrangeColor;
    //     color: $topbarColor;
    // }

    &:focus {
        box-shadow: none;
    }
}

.modal-content {
    border-radius: 0px !important;
}

.fa-times {
    cursor: pointer;
    color: $grayColor;

    &:hover {
        color: $blackColor;
    }
}

.btns-save-cancel {
    @include modelFooter;
}

.btn-save-up {
    @include buttonProperty($greenBlueColor, 30px, auto, 0, 15px, #FFF !important);
    padding: 5px 15px;
    margin-right: 10px;

    p {
        font-size: $fz14;
        font-weight: $fwt400;
        position: relative;
        bottom: 3px;
    }

    &:hover {
        color: $whiteColor;
    }
}

.btn-cancel {
    @include buttonProperty($lightGray, 30px, auto, 0, 15px, $whiteColor);
    padding: 5px 15px;
    margin-right: 10px;

    p {
        font-size: $fz14;
        font-weight: $fwt400;
        position: relative;
        bottom: 3px;
    }

    &:hover {
        background-color: $whiteColor;
        color: $blackColor;
        border: 1px solid $grayColor;
    }
}

.no-data-found {
    text-align: center;
    align-items: center;
    color: $nav_back_Graycolor;
    font-size: $fz14;
    margin: 10px auto;
}

#inputFieldErrorMsg {
    color: red !important;
    margin-top: 0px !important;
    position: absolute !important;
    font-size: $fz10 !important;
    left: 12px !important;
    top: 35px !important;
}

#inputFieldErrorMsgSuccess {
    color: green !important;
    margin-top: 0px !important;
    position: absolute !important;
    font-size: $fz11 !important;
    left: 12px !important;
    top: 38px !important;
}

#kilometerMsgSuccess,
#twolevelclaimMsgSuccess {
    color: green !important;
    margin-top: 0px !important;
    position: absolute !important;
    font-size: $fz11 !important;
    left: 12px !important;
    top: 38px !important;
}

.fa-angle-double-right,
.fa-angle-double-left {
    font-size: $fz10;
    font-weight: $fwt600;
    font-style: normal !important;
}

.error-input-message {
    font-size: $fz10;
    color: red;
    position: relative;
    left: 0px;
}

// Hide Input Number Arrows START
/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

// Hide Input Number Arrows END

// Select Dropdown CSS START
.react-select-container-list.per-page {
    width: auto !important;
}


.react-select-container-list {
    min-width: 140px;
    margin: auto 5px;

    .react-select-list__control {
        position: relative;
        box-shadow: none;
        border: 1px solid #c2c2c2;
        min-height: 30px !important;
        height: 30px !important;
        font-size: $fz13;
        border-radius: 5px;

        &:hover {
            border: 1px solid #c2c2c2;
        }

        .react-select-list__value-container {
            padding: 2px 0px 3px 5px;
            margin: 0px !important;
        }

        .react-select-list__indicator {
            padding: 2px 5px !important;
            // position: absolute;
            // right: 0px;
            // top: 2px;
        }

        .react-select-list__clear-indicator {
            padding: 2px 5px !important;
            position: absolute;
            right: 20px;
            top: 2px;
        }

        .react-select-list_menu {
            background-color: #c2c2c2 !important;
            max-height: 200px !important;

            &:hover {
                background-color: $OrangeColor !important;
            }
        }

        .react-select-list__input-container {
            margin: 0px;
        }

        .react-select-list__indicator-separator {
            display: none;
        }
    }
}

// Select Dropdown CSS END

#tooltip-googlemap {
    z-index: 9999;
}



#Errorfails {
    color: red !important;
}

/* DatePicker CSS END  */
.select.from-date {
    border-radius: 0px !important;
}

.select.to-date {
    margin-right: 8px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.from-to-date-select {
    align-items: center;
    flex-wrap: nowrap;

    .input-group-text {
        padding: 2px 5px;
        margin: 5px 0px;
        font-size: $fz13;
        background-color: darkgrey;
        color: black;
    }
}

.date-picker-with-icon {
    display: flex;
    width: 100px !important;
    position: relative;

    .fa.calander-icon {
        position: absolute;
        top: 13px;
        right: 5px;
        font-weight: 500;
    }
}

.react-datepicker-popper[data-placement^=bottom] {
    z-index: 2;
}

.select.single-date,
.select.from-date,
.select.to-date {
    width: 100px !important;
    padding: 2px 5px 2px 5px !important;
}

.select.single-date.react-datepicker-ignore-onclickoutside,
.select.from-date.react-datepicker-ignore-onclickoutside,
.select.to-date.react-datepicker-ignore-onclickoutside {
    box-shadow: none;
    outline: none;
}

.select.from-date,
.select.to-date {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: $greenBlueColor !important;
}


/* DatePicker CSS END  */

.react-time-picker {
    margin-left: 12px;
    background-color: white;

}

.react-time-picker__wrapper {
    border: 1px solid #c2c2c2;
    border-radius: 5px;
}

.react-time-picker__inputGroup {
    padding: 0 5px !important;
}

.react-time-picker__inputGroup__input {

    &:hover,
    &:focus {
        outline: none;
        box-shadow: none;
        border: none;
    }
}

.react-time-picker__clear-button.react-time-picker__button {
    display: none;
    padding: 0px;
}

.form-check-input:checked {
    background-color: $greenBlueColor !important;
    border-color: $greenBlueColor !important;

}

.form-check-input:focus {
    box-shadow: none !important;
    border-color: $greenBlueColor !important;
}

.bulk-temp-and-res-message {
    .upload-res-message {
        &.success {
            color: green;
        }

        &.false {
            color: red;
        }
    }
}

.apikey-search-input{
    position: relative;
    width: 100%;
    margin-right: 10px;
    .input-search-api-key{
        font-size: $fz14;
        width: 510px !important;
        height: 32px;
    }
    
    .fa-search{
        position: absolute;
        right: 30px;
        top:10px;
        color: $grayColor;
    }
}


/* PAGINATION PART START */

.user-pagination {
    margin-left: 10px !important;
}

.user-pagination ul {
    margin-bottom: 0px !important;
}

.page-link {
    color: #000000;
}

.page-link:focus {
    box-shadow: none;
}

.pagination {
    margin: auto;
    display: flex;
    list-style: none;
    outline: none;
}

.pagination>.active>a {
    background-color: $greenBlueColor;
    border-color: $greenBlueColor;
    color: $whiteColor;
}

.pagination>li>a {
    border: 1px solid $greenBlueColor;
    padding: 8px 15px;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    margin-right: 5px;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    background-color: $greenBlueColor;
    border-color: $greenBlueColor;
    outline: none;
}

.pagination>li>a,
.pagination>li>span {
    color: $greenBlueColor;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-radius: unset;
}

.pagination>li:first-child>a {
    border-radius: 5px 0px 0px 5px;
}

.pagination>li:last-child>a {
    border-radius: 0px 5px 5px 0px;
}

.pagination>.disabled a {
    color: rgb(198, 197, 202);
    border: 1px solid rgb(198, 197, 202);
}

.page-range {
    display: inline-flex;
    margin-left: 20px;
}

.css-t3ipsp-control{
    border-color: $lightGray !important;
    box-shadow: none !important;
    &:hover, &:visited{
    border-color: $lightGray !important;
    }
}

/* PAGINATION PART END */


@media screen and (max-width: 476px) {
    .page-wrapper .main-body.toggle-shift-left {
        width: calc(100% - 70px);
        position: relative;
        right: 10px;
        transition: all 0.35s ease;
        transform: translateX(0);
    }

    .topbar-nav .dropdown-toggle {
        position: relative;
        right: 15px;
    }

    .card-dashboard {
        margin-bottom: 10px;

        .card-body {
            display: block;
            width: 100%;
            text-align: center;
        }
    }

    .card-left-img,
    .card-right-data {
        padding-left: 0px !important;
    }

    .card-imag {
        padding-right: 0px !important;
        border-right: none !important;
    }

    .dash-board-left-side,
    .user-activity-right-dash {
        margin-bottom: 10px !important;
    }

    .profile-right-part {
        width: 100% !important;
    }

    .seleting-menus {
        display: flex;
        
        .select {
            margin: 5px;
            box-shadow: none;
            border: 1px solid #c2c2c2;
            height: 30px;
            font-size: $fz13;
            max-width: 100% !important;
            border-radius: 0px;
        }

        .btn-clear-select {
            width: 100% !important;
            margin-left: 5px !important;
        }

        .btn-add-user {
            width: 100% !important;
            margin-left: 5px !important;
            align-items: center !important;
            text-align: center !important;
        }
    }

}

@media screen and (max-width: 1280px) {

    // .page-wrapper .main-body{
    //     width: 83%;
    //     position: relative;
    //     margin-left: auto;
    //     right: 0px;
    //     padding: 75px 0px 15px 0px;
    //     transition: all 0.35s ease;
    //     transform: translateX(0);
    // }
    // .page-wrapper .main-body.toggle-shift-left{
    //     width: 95.5%;
    //     position: relative;
    //     right: 0px;
    //     margin-left: auto;
    //     transition: all 0.35s ease;
    //     transform: translateX(0);
    // }
    body {
        font-size: $fz13;
    }

    tbody td {
        font-size: $fz12 !important;
    }


    .row.view-on-map .col-md-9 {
        padding: 0px !important;
    }
}

.fz6 {
    font-size: $fz6;
}

.fz8 {
    font-size: $fz8;
}

.fz10 {
    font-size: $fz10;
}

.fz11 {
    font-size: $fz11;
}

.fz12 {
    font-size: $fz12;
}

.fz13 {
    font-size: $fz13;
}

.fz14 {
    font-size: $fz14;
}

.fz15 {
    font-size: $fz15;
}

.fz16 {
    font-size: $fz16;
}

.fz18 {
    font-size: $fz18;
}

.fz20 {
    font-size: $fz20;
}

.fz22 {
    font-size: $fz22;
}

.fz24 {
    font-size: $fz24;
}

.fz25 {
    font-size: $fz25;
}

.fz26 {
    font-size: $fz26;
}

.fz28 {
    font-size: $fz28;
}

.fz30 {
    font-size: $fz30;
}

.fz32 {
    font-size: $fz32;
}

.fz34 {
    font-size: $fz34;
}

.fz35 {
    font-size: $fz35;
}

.fz36 {
    font-size: $fz36;
}

.tb-td-sh{
    font-weight: 600;
    // color: $darkgrayColor;
}

.form-label {
    margin-bottom: 0px !important;
    color: $formLabelColor;
    font-size: $fz13;
}


/* SCROLL BAR CSS with Styling START */
/* width */
::-webkit-scrollbar {
    height: 10px;
    width: 10px;
    background-color: #F5F5F5;
}

/* Track */
::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $scrollerThumbColor;
    border-radius: 0px;

}


/* Handle on hover */
:-webkit-scrollbar-thumb:hover {
    background: rgb(102, 102, 102);
}

/* SCROLL BAR CSS with Styling END */