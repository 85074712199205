@import "variables";
@import "mixins";

.products-table {
    @include tableProperty;
}

.products-scrollbar {
    height: calc(100vh - 180px);
    margin: 10px;
}

.products-thead {
    @include tableHeadProperty;
}

.btn-add-product {
    @include buttonProperty($greenBlueColor, 30px, auto, 0, 14px, $whiteColor);
    margin-right: 10px;

    &:hover {
        color: $whiteColor;
        box-shadow: none;
        background-color: $greenBlueColor;
    }

    &:focus {
        box-shadow: none;
    }

    h6 {
        font-size: 14px;
        margin-left: 2px;
    }
}

.row-img-product {
    display: flex;
    align-items: center;

    .product-img-box {
        position: relative;
        display: flex;
        // background-color: $inputBox_backgroundColor;
        background-image: radial-gradient($whiteColor, $whiteColor, $lightGray 90%);
        border: 1px solid $lightGray;
        border-radius: 5px;
        padding: 3px;
        width: 50px !important;
        height: 50px !important;

        .row-product-images {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.product-table-row-data .product-edit-link {
    color: $greenBlueColor;
    font-size: $fz13;
    padding: 0px 5px !important;
}

.view-productdetails-model {
    .modal-content {
        width: 800px;
        position: relative;
        right: 120px;
    }
}

.product-image-box {
    position: relative;
    display: flex;
    margin: 10px;
    width: 100px !important;
    height: 100px !important;
    align-items: center;
    justify-items: center;
    padding: 5px;
    // background-color: $lightGray;
    background-image: radial-gradient($whiteColor, $whiteColor, $lightGray 70%);

    .product-images {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.product-details-model {
    padding: 20px;

    .product-detail-left-part {
        .product-big-img {
            position: relative;
            width: 100%;
            margin-bottom: 20px;
            transition: transform .2s;
            
            &:hover {
                    -ms-transform: scale(1.05);/* IE 9 */                  
                    -webkit-transform: scale(1.05);/* Safari 3-8 */                   
                    transform: scale(1.05);
                }

            .product-img {
                // background-color: $table_titleTop_backgroundColor;
                background-image: radial-gradient($whiteColor, $whiteColor, $lightGray 90%);
                border-radius: 10px;
                padding: 10px;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .product-small-images-row {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: space-between;

            .product-small-image {
                position: relative;
                width: 80px;
                height: 80px;
                transition: transform .2s;
                margin: 0 auto;
                &:hover {
                        -ms-transform: scale(1.1);/* IE 9 */                      
                        -webkit-transform: scale(1.1);/* Safari 3-8 */                        
                        transform: scale(1.1);
                    }

                .product-img {
                    // background-color: $table_titleTop_backgroundColor;
                    background-image: radial-gradient($whiteColor, $whiteColor, $lightGray 70%);
                    border-radius: 10px;
                    padding: 5px;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }

    .product-detail-right-part {
        .product-name {
            margin-bottom: 20px;

            h5 {
                font-size: $fz18;
                font-weight: bold;
            }
        }

        .product-details-by-row {
            padding-bottom: 10px;
            border-bottom: 2px solid $lightGray;

            .product-row {
                margin-bottom: 10px;
                font-size: $fz13;
                width: 100%;
                display: flex;

                .pdh {
                    width: 165px !important;
                    max-width: 165px;
                    font-weight: $fwt600;
                    color: $greenBlueColor;
                }

                .pdd {
                    color: $darkgrayColor;
                    width: 200px !important;
                    max-width: 200px;
                }
            }
        }

        .product-description {
            margin: 10px 0;

            .description-instruction {
                .description-instruction-nav-tab {
                    .nav-link {
                        color: $darkgrayColor;

                        &.active {
                            color: $greenBlueColor;
                        }
                    }
                }

                .tab-content {
                    margin-top: 10px;

                    p {
                        word-break: break-word;
                        text-align: justify;
                    }
                }
            }
        }
    }

}

/* ADD & EDIT CLIENT MODEL START */
.main_model {
    background-color: #b8b8b896;
    padding: 10px;
    z-index: 9999;
}

.add-product-input {
    border-radius: 5px;
    font-size: 13px;
    background-color: rgba(250, 250, 250, 0.8549019608);
}

.add-edit-product-model {
    .modal-content {
        width: 80% !important;
        margin-left: 50px;
    }

    .add-edit-product {
        padding: 10px 20px;

        .btns-save-cancel {
            display: inline-flex;
            margin-bottom: 10px;
            align-items: center;
            text-align: center;
            justify-content: center;
        }

        .btn-save-up {
            background-color: $greenBlueColor;
            color: $whiteColor;
            border-radius: 5px;
            padding: 5px 15px;
            height: 30px;

            p {
                font-size: 15px;
                font-weight: 400;
                position: relative;
                bottom: 3px;
            }

            &:hover {
                color: $whiteColor;
            }
        }

        .btn-cancel {
            background-color: $grayColor;
            color: $whiteColor;
            border-radius: 5px;
            padding: 5px 15px;
            margin-right: 10px;
            height: 30px;

            p {
                font-size: 15px;
                font-weight: 400;
                position: relative;
                bottom: 3px;
            }

            &:hover {
                color: $whiteColor;
            }
        }
    }
}

.add-edit-product-heading {
    @include modelHeader;
}

.form-control:focus {
    box-shadow: none;
    border-color: #b3b3b3;
}

.btn-add-product-img {
    width: 100%;
    background-color: $lightGray;
    color: $blackColor;
    text-align: center;

    &:hover {
        color: $whiteColor;
        background-color: $grayColor;
    }
}


/* SCROLL BAR CSS with Styling START */
/* width */
.products-scrollbar::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
.products-scrollbar::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor;
}

/* Handle */
.products-scrollbar::-webkit-scrollbar-thumb {
    background: $scrollerThumbColor;
    border-radius: 0px;
}

/* Handle on hover */
.products-scrollbar:-webkit-scrollbar-thumb:hover {
    background: rgb(102, 102, 102);
}

/* SCROLL BAR CSS with Styling END */