@import "variables";
@import "mixins";

.profile-table .profile-page-title {
    font-size: $fz16 !important;
    font-weight: $fwt700 !important;
    margin-left: 10px !important;
    flex: auto;
}

.profile-edit-info-row {
    background-color: $whiteColor;
    height: calc(100vh - 130px);
    padding: 10px 10px 0px 10px;
}

.profile-left-part,
.profile-right-part {
    margin: 20px 10px !important;
}

.profile-right-part {
    width: 38%;
}

.edit-info-head h6,
.change-password-head h6{
    font-size: $fz14;
    padding-bottom: 5px;
    border-bottom: 1px solid $grayColor;
    color: $darkgrayColor;
}

.profile-left-part, .profile-right-part{
    .edit-info, .change-password {
        margin-top: 20px;
    }
    .profile-image-section {
        text-align: center;
        .btn-add-user-img-profile{
            background-color: transparent;
            font-size: $fz14;
                }
    }
} 

.file-name-display{
    font-size: $fz10;
    color: $OrangeColor;
    line-break: anywhere;
    display: -webkit-inline-box;
}

.profile-image-section .change-profile-img {
    justify-content: center;
    margin-top: 10px;
}

.profile-page.profile-img {
    width: 135px;
    height: 135px;
    align-items: center;
    border-radius: 25px;
    background-color: $inputBox_backgroundColor;
}

.change-profile-img p,
.change-profile-img i {
    color: $greenBlueColor;
    padding: 3px;
}

.input-group .edit-info-inputs {
    border: none;
    border-radius: 0px;
    background-color: $inputBox_backgroundColor;
    font-size: $fz13;
}

#user-icon,
#email-icon,
#phone-icon {
    background-color: #00b63da1;
    color: #00463a;
    border: none;
}

.btn-success.update {
    width: 100%;
    background-color: $greenBlueColor;
    border-radius: 0px;
    border: none;
}


.meeting-range-enable-radio-status {
    margin-right: 15px;
}



@media screen and (max-width: 1280px) {
    .profile-left-part,
    .profile-right-part {
        margin: 10px 5px 0px 5px !important;
    }
}
