
// *----- COLOR VARIABLES ------*//

// $mainBody_backgroundImg: url(../images/bg.png);
$mainBody_backgroundImg: #ebf3f1;
// $greenBlueColor: #1C8582;
$greenBlueColor: #04A96D;
$darkOrangeColor: #FFB900;
$OrangeColor: #ff7b00;
$OrangeRed: orangered;
$statusOrange: rgb(255, 101, 83);
$statusGreen: #4ba83fea;
$statusYellow: #ffbb00e1;
$blackColor: #000000;
$textHeadColor: #7c7c7c;
$topbarColor: #5d6064;
$inputFieldback_color:#fafafada;
$whiteColor: #fff;
$nav_back_Graycolor: #1b1e24;
$nav_link_Textcolor: #8b91a0;
$card_BoxShadow: 0px 1px 1px 0px rgb(0 0 0 / 20%);
// $table_BoxShadow: 0px 1px 1px 0px rgb(0 0 0 / 20%);;
$table_BoxShadow: 0px 0px 8px -1.9px rgba(0, 0, 0, 0.2);
$table_titleTop_backgroundColor: #f6faf9;
$table_titleTop_borderBottom: 0px solid #5c5c5c26;
// $tablebody_backgroundColorG: #f4fffb;
// $tablebody_backgroundColorY: #fff9e6;
$tablebody_backgroundColorG: #ffffff;
$tablebody_backgroundColorY: #EDFCF9;
$tableHead_backgroundColor: #323232;
$tableHead_background_Color: #EFEFEE;
$inputBox_backgroundColor: #e9fcf5;
$tableHead_textColor: #ffffff;
$tableHead_text_Color: #566E5B;
$grayColor: #919191;
$darkgrayColor: #5f5f5f;
$lightGray:#e4e4e4;
$formLabelColor :#2e2e2e;
$navLinkColor: #001988;
$scrollerBackgroundColor: #a0a0a079; 
$scrollerThumbColor: #6969694f;
$warningColor : #ffcc00;
$danderColor : #cc3300;
$successColor : #339900;

// *----- FONT SIZE & WEIGHT ------*//

$tableHead_textSize: 15px;
$tableData_headTextSize: 0.9rem;
$tableHead_fontWeight: 500;

$fwt300: 300;
$fwt400: 400;
$fwt500:500;
$fwt600: 600;
$fwt700:700;
$fwt800: 800;

$fz6 : 6px;
$fz8 : 8px;
$fz10 : 10px;
$fz11 : 11px;
$fz12 : 12px;
$fz13 : 13px;
$fz14 : 14px;
$fz15 : 15px;
$fz16 : 16px;
$fz18 : 18px;
$fz20 : 20px;
$fz22 : 22px;
$fz24 : 24px;
$fz25 : 25px;
$fz26 : 26px;
$fz28 : 28px;
$fz30 : 30px;
$fz32 : 32px;
$fz34 : 34px;
$fz35 : 35px;
$fz36 : 36px;


