@import "variables";

#header {
    position: absolute;

    .pro-sidebar {
        height: 100vh;
        width: 100%;
        min-width: 100%;
    }

    .pro-sidebar-inner .pro-sidebar-layout {

        ul {
            padding: 0px;
        }
    }
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-header {
    border-bottom: none !important;
}

.side_bar_header_main {
    background-color: #fff !important;
    align-items: center !important;
    // padding: 0.3rem;
    // width: 300px;
    padding: 8px 6px;
}


.salesbee-logo {
    width: 80%;
    position: relative;
    left: 10px;
    bottom: 3px;
}

.profile-image-sidebar-big {
    // background-color: #f1fffa;
    background-color: #fff;
    display: block;
    padding: 15px;
    // border-bottom: 2px solid #04a96d;
    // height: 180px;

    .profile-image {
        img {
            width: 60px;
            height: 60px;
            border: 3px solid #FFB900;
            // border: linear-gradient(to right, #FFB900, #04A96D);
            border-radius: 15px;
            position: relative;
            left: 60px;
            vertical-align: middle;
        }
    }
}

.travelize_logo {
    text-decoration: none;
    color: $whiteColor;
    font-size: $fz36;
    font-weight: $fwt400;
    line-height: 1.13;
    word-spacing: 4px;
    margin: 0px 6px;
    // font-family: 'Saira Stencil One', cursive;    
    font-family: 'Bree Serif', serif;
    max-width: 215px;

    &:hover {
        color: $whiteColor;
    }
}

.travelize-logo-pro {
    font-size: $fz12;
    color: $whiteColor;
    font-weight: $fwt300;
    // font-family: 'Koulen', cursive;
    font-family: 'Bree Serif', serif;
    vertical-align: super;
    top: -0.2rem;
    right: 0.5rem;
}

.profile-data-name {
    text-align: center;
    font-weight: $fwt600;
    color: #000;
    padding-top: 5px;
    font-size: 16px;
}

.profile-data-title {
    text-align: center;
    font-size: $fz13;
    color: #04a96d;
    font-weight: 800;
}

.fa-icon-div {
    width: 20px;
}

.profile-controls {
    color: $whiteColor;
    position: relative;
    bottom: 110px;

    .fa {
        color: $whiteColor;
    }

    .profile-control-left .fa {
        padding: 5px 10px;
        border-radius: 5px;
        border: 2px solid $whiteColor;
    }

    .profile-control-right .fa {
        padding: 5px 5px;
        border-radius: 5px;
        border: 2px solid $whiteColor;
        position: relative;
        left: 130px;
    }
}

.nav-link {
    margin-left: 0px;
}

.sidebar_content {
    background-color: #fff;
    overflow-y: auto;
    padding-bottom: 15px;

    .expanded-nav-bar {
        li {
            list-style: none;
            padding: 10px;

            &:hover a {
                color: $whiteColor;
            }

            &:focus a {
                color: $whiteColor;
            }
        }

        // .sidebar-item:hover {
        //     background-color: #292d35b6;
        //     color: $whiteColor;
        // }

        a {
            text-decoration: none;
            color: #5d6064;
        }

        .xn-text {
            margin-left: 15px;
            font-size: $fz16;
            font-weight: $fwt400;
        }
    }
}

.sidebar-item .nav-link {
    padding: 0px !important;
}

.drop-main:hover {
    color: #04a96d !important;
    // background-image: linear-gradient(45deg, #04A96D, #5aa904);
        // background-color: $greenBlueColor !important;
        // border-left: 4px solid $darkOrangeColor !important;
}
.drop-main:hover .sidebar-title {
    color: #04a96d !important;
}
.expanded-nav-bar {

    .nav-link-item.active,
    .sidebar-item.plain.active{
                position: relative;
        color: #04a96d !important;
        // background-color: $greenBlueColor !important;
        background-image: linear-gradient(to right, rgba(66, 138, 121, 0.1) 30%, transparent 100%);
        // border-left: 4px solid $darkOrangeColor !important;
    }

        .sidebar-item.plain:hover {
            position: relative;
            color: #04a96d !important;
        }
    
    .nav-link-item.active::before,
    .sidebar-item.plain.active::before
             {
            content: "";
                height: 30px;
                top: 50%;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                width: 3px;
                background-color: #04a96d;
                position: absolute;
                left: 0;
                border-radius: 0 3px 3px 0;
    }
     

    .nav-link-item.active .sidebar-title {
        color: #04a96d;
    }
}

// .drop-down-item {
//     padding: 8px 10px 8px 30px
// }

.sidebar-item .sidebar-content.sub-active .nav-link-item {
    // background-color: #FFB9000;
    // background-image: linear-gradient(45deg, #ffb900, #b1bb17);
    //         border-left: 4px solid #04A96D;
}

.sidebar-item {
    cursor: pointer;

    &:hover {
        cursor: pointer;
    }

    .sidebar-content {
        height: 0;
        overflow: hidden;

        .nav-link-item:hover {
            color: #04a96d;
        }

        &.sub-active .nav-link-item {
            color: #04a96d;

            .xn-text {
                color: #04a96d;
                font-weight: 500;
            }
        }

        .nav-link-item .bi {
            font-size: $fz10;
        }
    }

    &.open .sidebar-content {
        height: auto;
        vertical-align: middle;
        // padding: 8px 10px 8px 30px;

        // &:hover {

        //     .nav-link-item {
        //         color: white;
        //         padding: 8px 10px 8px 30px;
        //     }
        // }

        .nav-link-item .xn-text {
            font-size: $fz13;
        }
        .nav-link-item {
            padding: 8px 10px 8px 30px;
            
        }
    }



    &.open {
        .nav-link-item.active .sidebar-title .toggle-btn {
            cursor: pointer;
            transition: transform .1s;
        }

        .nav-link-item.active>.sidebar-title .toggle-btn {
            transform: rotate(180deg);
        }

        .active .sidebar-title {
            color: #04a96d;
        }
    }

    &.plain {
        &.active a {
            color: $whiteColor;
            text-decoration: none;
        }

        i {
            display: inline-block;
            width: 1.7em;
        }
    }
}

.sidebar-title {
    align-items: center;
    color: #5d6064;
    display: flex;

    &:hover {
        color: #04a96d;
    }

    .bi-chevron-down.toggle-btn {
        margin-left: auto;
        margin-right: 10px;
    }
}




/* COLLAPASED NAV BAR START */

.collapsed-nav-bar {
    overflow-y: hidden;

    li {
        list-style: none;
        text-align: center;

        // &:hover {
        //     background-color: $blackColor;
        // }

        >a {
            text-decoration: none;
            color: $nav_link_Textcolor;
        }

        &:hover>a {
            color: #04a96d;
        }

        >a.active {
            color: #04a96d;
        }
    }

    .fa {
        margin: 10px 0px;
        font-size: $fz18;
        padding: 5px;
        font-weight: $fwt600;
    }
}

.profile-mini {
    justify-content: center;
    text-align: center;
    align-items: center;

    .profile-image {
        padding: 2px;
    }
}

.profile-image img {
    width: 40px;
    position: relative;
    border: 3px solid#FFB900 !important;
    border: #FFB900;
    border-radius: 8px;
    padding: 0px;
    background-color: $whiteColor;
}

/* COLLAPASED NAV BAR END */


/* width */
.sidenavbar-scroll::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    // background-color: $darkOrangeColor !important;
    border-radius: 6px !important;
}

/* Track */
.sidenavbar-scroll::-webkit-scrollbar-track {
    // background: $darkOrangeColor;
}

/* Handle */
.sidenavbar-scroll::-webkit-scrollbar-thumb {
    background: $darkOrangeColor;
    border-radius: 10px !important;
}

/* Handle on hover */
.sidenavbar-scroll:-webkit-scrollbar-thumb:hover {
    background: rgb(168, 168, 168);
}

.setting-image {
 position: fixed;
 z-index: 2;
 bottom: 5%;
 right: 5%;
 background-color: #fff;
 border-radius: 50%;
 padding: 10px;
 width: 50px;
 height: 50px;
 box-shadow: rgba(4, 169, 109, 0.318) 0px 5px 20px 0px;
 cursor: pointer;
}

.setting-image img {
    width: 100%;
    animation-name: spin;
        animation-duration: 4000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    
        @-ms-keyframes spin {
            from {
                -ms-transform: rotate(0deg);
            }
    
            to {
                -ms-transform: rotate(360deg);
            }
        }
    
        @-moz-keyframes spin {
            from {
                -moz-transform: rotate(0deg);
            }
    
            to {
                -moz-transform: rotate(360deg);
            }
        }
    
        @-webkit-keyframes spin {
            from {
                -webkit-transform: rotate(0deg);
            }
    
            to {
                -webkit-transform: rotate(360deg);
            }
        }
    
        @keyframes spin {
            from {
                transform: rotate(0deg);
            }
    
            to {
                transform: rotate(360deg);
            }
        }
}