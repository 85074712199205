@import "variables";
@import "mixins";


/* ADD & EDIT CLIENT MODEL START */
.main_model {
    background-color: #b8b8b896;
    padding: 10px;
    z-index: 9999;
}

.add-input {
    border-radius: 5px;
    font-size: $fz13;
    background-color: rgba(250, 250, 250, 0.8549019608);
}

.add-edit-model {
    .modal-content {
        width: 80% !important;
        margin-left: 50px;
    }

    .add-edit {
        padding: 10px 20px;

        .btns-save-cancel {
            display: inline-flex;
            margin-bottom: 10px;
            align-items: center;
            text-align: center;
            justify-content: center;

            p {
                font-size: $fz15;
                font-weight: $fwt400;
                position: relative;
                bottom: 3px;
            }

            &:hover {
                color: $whiteColor;
            }
        }

        .btn-save-up {
            background-color: $greenBlueColor;
            color: $whiteColor;
            border-radius: 5px;
            padding: 5px 15px;
            height: 30px;

            p {
                font-size: $fz15;
                font-weight: $fwt400;
                position: relative;
                bottom: 3px;
            }

            &:hover {
                color: $whiteColor;
            }
        }

        .btn-cancel {
            background-color: $grayColor;
            color: #FFF;
            border-radius: 5px;
            padding: 5px 15px;
            margin-right: 10px;
            height: 30px;

            p {
                font-size: $fz15;
                font-weight: $fwt400;
                position: relative;
                bottom: 3px;
            }

            &:hover {
                color: $whiteColor;
            }
        }
    }
}

.add-edit-heading {
    @include modelHeader;
}

.form-control:focus {
    box-shadow: none;
    border-color: #b3b3b3;
}

