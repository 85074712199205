@import "variables";
@import "mixins";


p {
    color: $topbarColor;
}
.page-content-dash {
    position: relative;
    padding: 15px 0px;
}

.sb-card-row {
    display: flex;
    justify-content: center;
    align-items: center;
}
.card-dashboard {
    border-radius: 0px !important;
    border: none;
    width: 20%;
    padding: 0px 10px;
    background-color: transparent;
    // box-shadow: $card_BoxShadow;

    .card-body {
        padding: 5px !important;
        display: flex;
        justify-content: center;
        background: #fff;
        min-height: 120px;
        max-height: 120px;
        &:hover {
                border-radius: 0px !important;
                border: none;
                box-shadow: $table_BoxShadow;
            }
    }
    
}

.card-row {
    align-items: center;
    width: 100%;
}
.verticle-line {
    border-right: 3px solid #f5f5f5;
    height:40px;
    padding-left: 4px;;
}
.sb-grap-text {
    line-height: 20px;
}

.grap-imag {
    width: 100%;
    padding: 0px;
}
.grap-box {
    width: 8px;
    height: 8px;
}
.box1 {
    background-color: $greenBlueColor;
}
.box2 {
    background-color: #74d9b4;
}
.box3 {
    background-color: #d1ebe1;
}
.attend-card {
    padding: 0px 20px;
    text-align: left;
}
.client-card {
    padding: 0px 5px;
}
.client-text{
    font-size: 18px;
    font-weight: 400 !important;
}
.sb-grap-text p {
    font-size: 18px;
}
.card-left-img {
    display: flex;
    padding: 0px;
}

.meeting-row-div {
    padding: 10px;
}
.sb-meeting-grap-row {
    padding: 6px 2px;
}
.badge {
    padding: .35em .25em;
    font-size: .60em;
    min-width: 30px;
}

.bd1 {
    background-color: $greenBlueColor !important;   
}
.bd2 {
    background-color: #74d9b4 !important;   
}
.bd3 {
    background-color: #aec3bb !important;   
}

.grap-img {
    padding: 25px 0px;
}

.card-imag {
    width: 100%;
    // height: 38px;
    padding: 0px 5px;
}

.act-body {
    width: 100%;
}
.card-right-data {
    display: flex;
    align-items: center;
    padding: 0px;
    text-align: center;
    a{
        color: $topbarColor;
        &:hover{
            color: $topbarColor;
        }
    }
}

.meeting-grap-section {
    position: relative;
}

.sb-complete-meeting {
    position: absolute;
    left: 20%;
}

.sb-claims {
    position: absolute;
    left: 40%
}

.sb-complete-meeting p{
    font-size: 7px !important;
}

.card-head {
    @include paddingMarginSetZero(0, 0);
    font-size: 20px;
    font-weight: 600;
}

.sb-meeting-grap-div {
    background-color: #fff;
}

.meetings-sort {
    &.select-by-dropdown {
        padding: 3px 6px;
        margin-left: 10px;
        border: 1.5px solid $lightGray;
        font-size: $fz18;
    }

    &:focus {
        border: 1.5px solid $lightGray;
        outline: none;
    }

    option {
        background-color: $whiteColor;
        border: none;
        outline: none;
    }
}

.act-num {
    font-size: $fz18;
}

.dashboard-user-details {
    margin-top: 20px;
}

.user-activity-right-dash {
    height: 420px;
    overflow-y: auto;
    background-color: $whiteColor;
}

.dash-board-left-side,
.user-activity-right-dash {
    border-top-color: #F5F5F5;
    border-top-width: 1px;
    box-shadow: $table_BoxShadow;
    border-top: 2px solid #E5E5E5;
    border: 0px;
    height: 450px;
    background-color: $whiteColor;
}

.dash-board-left-side .see-all-user-lish-dash {
    padding: 12px 3px;
    background-color: #ffffff;

    .see-all-link {
        color: $greenBlueColor;
        text-decoration: none;
        margin-left: 10px;
    }
}

.sb-db-table-th {
    padding: 0.5rem 0.3rem !important;
    font-size: 18px;
    // width: 23%;
}
.sb-db-tbody td {
 font-size: 18px ;
 height: 30px ;
}

.sb-data-col{
    padding-right: 0px !important;
}

.userlist-thead {
    background-color: #ecf4f2;
        color: $topbarColor;
        font-size: $fz18;
        font-weight: $fwt500;
        width: max-content;
        border-bottom: $table_titleTop_borderBottom;
        vertical-align: middle !important;
        position: sticky;
        top: 0;

        
}

.sb-ds-link {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
}

.activity-page-link,
.attendance-page-link {
    text-decoration: none;
    color: $blackColor;

    &:hover {
        color: $blackColor;
    }
}

.sch-meeting-head img,
.direct-visit-head img {
    width: 20px;
    height: 20px;
}

.meeting-grap-head {
    background-color: #fff;
    box-shadow: 0px 2px 2px #eee !important;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sort-left-dash {
    font-weight: $fwt600;
    font-size: $fz18;
}
.user-list-top-head {
    background-color: $table_titleTop_backgroundColor;
    box-shadow: 0px 2px 2px #eee !important; 
    padding: 15px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .sort-left-dash {
        display: inline-flex;

        i {
            margin-left: 10px;
        }
    }

    .selct-drop-by-dash {
        font-weight: 600;
        font-size: $fz18;
        float: right;
    }
}

.act-heading, .refresh-activity {
    font-weight: 600;
    font-size: $fz18;
}

.userlist-thead-activity-table {
    background-color: $table_titleTop_backgroundColor;
    box-shadow: 0px 2px 2px #eee !important;
    padding: 8px 15px;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 2;

    .act-top-head {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .act-heading {
            font-weight: 600;
            // font-size: $fz13;
        }
    }

    // .search-and-refresh-part{
    //     float: right;
    // }
}

.user-activity-data {
    min-height: 580px;
    max-height: 580px;
    // overflow-y: scroll;
    .user-activity-rowdata{
        padding: 5px !important;
    }
    .act-right-body {
        display: flex;
        align-items: center;
        border-bottom: 1.5px dotted $darkgrayColor;
    }

    td {
        padding: 8px 6px !important;
    }
}

.dashboard.user-meeting-dash-table {
    height: 360px;

    td:nth-child(1) {
        text-align: left;
    }

    td {
        text-align: center;
    }

    tbody>tr:nth-child(odd)>td {
        background-color: #F8FAFC !important;
    }
}

.sb-db-map {
    padding: 0px 10px;
}


.activity-search {
    justify-content: flex-end;

    input {
        max-width: 250px;
        border-radius: 0px !important;
    }
}

.table-user-image {
    position: relative;
    text-align: center;
    align-items: center;
    padding: 10px 5px;
    // margin-left: 10px;

    h6 {
        margin-top: 5px;
        margin-bottom: 0px;
        font-size: $fz18;
        font-weight: $fwt600;
        color: $textHeadColor;
    }

    p {
        padding: 2px;
        font-size: $fz11;
        font-weight: $fwt600;
        color: $greenBlueColor;
        cursor: pointer;
    }
}

.table-data-user-img {
    width: 100%;
    // height: 70px;
    border-radius: 50%;
    border: 2px solid $lightGray;
}

.td-user-location {
    // margin-bottom: 5px;
    // padding: 5px;
    // max-width: 400px;
    font-size: $fz18;
    font-weight: $fwt400;
    color: $blackColor;
}

.act-icon {
    margin: 0px;
    min-width: 60px;
    position: relative;
    padding: 2px;
}

.activity-icons {
    width: 35px;
    height: 35px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 5px 5px;
    border: 1pt solid $greenBlueColor;
    border-radius: 0px;
    align-items: center;
    // margin: 5px;

    &.meeting {
        border-radius: 15px !important;
    }

    .fa,
    .fas {
        font-size: $fz14;
        color: gray;
    }
}


.user-activity-images {
    display: flex;
    position: relative;
    // align-items: center;
    padding: 15px 0px;
    text-align: center;
    justify-content: space-around;

    p {
        font-size: $fz16;
        margin-top: 8px;
        margin-bottom: 0px;
    }

    .recent-activity-meeting-count {
        position: absolute;
        top: -10px;
        right: 30px;
        font-size: $fz11;
        background-color: $greenBlueColor;
        padding: 0px 5px;
        border-radius: 10px;
        color: white;
    }
}

.user-activity-table {
    width: 100%;
}

.no-activity-found {
    margin: 10px;
    text-align: center;
}

.refresh-button {
    margin-right: 5px;
    background-color: #f6faf9;
    box-shadow: rgb(102, 102, 102);
    border: none;

    .fa-refresh {
        color: $greenBlueColor;
    }

    &:hover {
        // background-color: $whiteColor;
        box-shadow: none;
    }

    &:focus {
        box-shadow: none;
    }
}


@media screen and (max-width: 476px) {
    .card-right-data {
        padding-left: 0px !important;
        text-align: center;
    }
}

@media screen and (max-width: 1280px) {
    .card-left-img {
        // padding-left: 10px;
    }
    .act-icon{
        min-width: 40px;
    }
    .activity-icons {

        .fa,
        .fas {
            font-size: $fz18;
        }
    }

    .recent-activity-meeting-count {
        position: absolute;
        top: -8px;
        right: 41px;
    }
    
}

/* SCROLL BAR CSS with Styling START */
/* width */
.user-activity-right-dash::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
.user-activity-right-dash::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor;
}

/* Handle */
.user-activity-right-dash::-webkit-scrollbar-thumb {
    background: $scrollerThumbColor;
    border-radius: 0px;
}

/* Handle on hover */
.user-activity-right-dash:-webkit-scrollbar-thumb:hover {
    background: rgb(102, 102, 102);
}

.dash-board-left-side,
.user-activity-right-dash {
    height: auto;
}

// .apexcharts-canvas{
//     height: 80px !important;
// }
// .apexcharts-canvas svg{
//     height: 100% !important;
// }

// .apexcharts-pie {
//     transform: translate(-22px, 0px) scale(1, 1) !important;
// }

.meeting-donut .apexcharts-canvas .apexcharts-inner .apexcharts-pie {
    transform: translate(-22px, 40px) scale(3) !important;
}

// .apexcharts-legend {
//         top: 11px;
//             right: 5px;
// }

/* SCROLL BAR CSS with Styling END */