@import "variables";
@import "mixins";

.main_model {
    background-color: #b8b8b896;
    padding: 10px;
    z-index: 9999;
}

.model-add-meeting-body {
    padding: 15px 20px;
}

.add-meeting-heading {
    @include modelHeader;
}

.select-add-meeting-model, .add-meeting-input, 
.add-client-input, .select-add-client-model {
    // color: $grayColor;
    border-radius: 5px;
    font-size: $fz13;
    background-color: $inputFieldback_color;
}
.meeting-date-picker-with-icon{
    position: relative;
    i{
        position: absolute;
        right: 10px;
        top: 8px;
    }

    .select-add-meeting-model.meeting-date{
        width: 100%;
        border-radius: 5px !important;
        font-size: $fz13 !important;
        background-color: $inputFieldback_color;
        height: 33px !important;
        padding: 0.26rem 2.25rem 0.38rem 0.75rem;
        border: 1px solid #ced4da;
            }
}
.select-add-meeting-model{
    .css-t3ipsp-control{
        border: 1px solid #ced4da !important;
        box-shadow: none;
    }    
}
.meeting-date-picker-with-icon{
input:focus-visible {
    box-shadow: none;
    border: 1px solid #ced4da;
    outline: none;
}
}

.css-1anqmj6-MuiPopper-root-MuiPickersPopper-root{
    z-index: 9999 !important;
}

.add-meeting .btn,
.add-meeting .form-control {
    box-shadow: none;
}

.add-meeting .form-control:focus {
    border: 1px solid #b1b1b1;
}

.btn-add-client-name {
    background-color: $blackColor;
    color: $whiteColor;
    position: relative;
    top: 1px;
    right: 20px;
    padding: 2px 5px;
    &:hover {
        background-color: $blackColor;
        color: $whiteColor;
    }
    &:focus {
        box-shadow: none;
    }
    .fa-plus-circle {
        position: relative;
        top: 2px;
        font-size: $fz20;
        font-weight: $fwt700;
    }
}
 

.add-meeting-input.location {
    height: auto;
}

.add-client-model {
    background-color: #b8b8b896;
    z-index: 222222;
    .modal-content {
        width: 65% !important;
        height: auto;
        position: relative;
        left: 515px;
        top: 10px;
    }
}

.add-client-heading {
    @include modelHeader;
}

.select-add-client-model {
    color: $grayColor;
}

.enable-radio {
    position: relative;
    left: 10px;
    vertical-align: middle;
    align-items: center;
}

.enable-radio-status {
    margin-left: 15px;
}