@import "variables";
@import "mixins";

.page-content {
    @include pageContentProperty;
}

.tracker-table {
    @include tableProperty;
}

.page-title {
    @include pageTitle;
}

.trackers-list-thead {
    @include tableHeadProperty;
}
.tracker-tabel-scroll{
    margin: 10px;
    overflow-x: hidden;
}

.track-list-name {
    @include tableDataImgTxt;    
    h6 {
        font-size: $tableData_headTextSize;
    }
}

.table-data-track-imgages {
    @include userImgProperty(35px);
    margin-right: 10px;
    border-radius: 50%;
    height: 35px;
}

.tracker-details-table{
    height: 420px;
}

.tracker-page-google-map {
    position: relative;
    .google-map-box {
        position: relative;
        top: 0;
        left: 0;
        width: 100% !important;
        text-align: center;
        h5 {
            margin-bottom: 0px;
        }
    }    
}

