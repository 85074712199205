@import "variables";

@mixin paddingMarginSetZero($padd, $marg) {
    padding: $padd !important;
    margin: $marg !important;    
}

@mixin mainBody {
    width: calc(100% - 205px);
    position: relative;
    margin-left: auto;
    right: 0px;
    padding: 70px 0px 15px 0px;
    min-height: 100vh;
    background:#f5f5f5;
}


@mixin toggleShiftLeft {
    width: calc(100% - 60px);
    position: relative;
    right: 0px;  
    margin-left: auto;
}

@mixin pageContentProperty {    
    position: relative;
    right: 0px;
    // left:12px;
    background-color: $whiteColor;
    border-top-color: #F5F5F5;
    border-top-width: 1px;
    // box-shadow: $table_BoxShadow;
    border-top: 2px solid #E5E5E5;
    border: 0px;
    margin: 0px 20px;
    // width: 98%; 
    height: auto;
    min-height: 88vh;
}

@mixin tableHeadProperty {
    background-color: $tableHead_background_Color;
    color: $tableHead_text_Color;
    font-size: $fz13;
    font-weight: $fwt500;
    width: max-content;
    border-bottom: $table_titleTop_borderBottom;
    vertical-align: middle !important;
    position: sticky;
    top: 0;
}

@mixin tableBodyProperty {
    background-color: #ffffff !important;
    padding: 5px !important;
    font-size: $fz14;
}

@mixin tableProperty {
    position: relative;
    width: 100%;
    height: auto;
    background-color: $whiteColor;
}

@mixin pageTitle {
    flex: auto;
    font-weight: $fwt700;
    margin-left: 10px;
}

@mixin selectingMenus {
    display: flex;
    align-items: center;
    background-color: $table_titleTop_backgroundColor;
    border-bottom: 2px solid rgba(92, 92, 92, 0.1490196078);
    padding: 5px;
    position: sticky;
    top: 0;
}

@mixin SelectDropdowns{
    margin: 5px;
    box-shadow: none;
    border: 1px solid #c2c2c2;
    height: 30px;
    font-size: $fz13;
    max-width: max-content !important;
    border-radius: 5px;
}

@mixin buttonProperty($bgColor, $height, $width, $radius, $txtSize, $txtColor){
    background-color: $bgColor;
    height: $height;
    width: $width;
    border-radius: $radius;
    font-size: $txtSize;
    color : $txtColor;
    box-shadow: none;
}

@mixin userImgProperty($width) {
    width: $width;
    opacity: 1;
}

@mixin tableDataImgTxt {
    display: inline-flex;
    align-items: center;
    padding: 3px;
    vertical-align: middle;
}
@mixin  modelHeader {
    background-color: $table_titleTop_backgroundColor;
    align-items: center;
    padding: 14px 15px 14px 15px;
    border-bottom: 1px solid #d5d5d5;
    display: flex;
    justify-content: space-between;
}

@mixin modelFooter {
    background-color: $table_titleTop_backgroundColor;
    padding: 10px 10px 10px 15px;
    border-top: 1px solid #d5d5d5;
    display: flex;
    justify-content: flex-end;
}


