@import "variables";
@import "mixins";

.meetings-table {
    @include tableProperty;
}

.googleapi-table{
    position: relative;
    width: 100%;
    height: auto;
    background-color: $whiteColor;
    padding-bottom: 10px;
}

.meetings-scrollbar {
    height: calc(105vh - 210px);
    // height: 65vh;
    padding: 10px 0px 20px 0px;
    margin: 10px;
}

.google-apihitcount-scrollbar{
    height: calc(100vh - 180px);
     margin: 15px;
}

// .apihitcount-list-table{

// }

.meetings-list-thead {
    @include tableHeadProperty;
    position: sticky;

    .select-all-check-box-text {
        align-items: center !important;
        display: flex;

        .select-all-checkbox {
            margin: 5px 5px 5px 5px !important;
            width: 18px;
            height: 18px;
        }
    }
}

.select-single-checkbox {
    width: 16px !important;
    height: 16px !important;
    margin-right: 15px;
}

.from-to-date-select .input-group-text {
    padding: 5px 5px;
    // margin: 5px 0px;
    font-size: $fz13;
    background-color: darkgrey;
    color: $blackColor;
}

#from-datepicker {
    margin-right: 0px;
}

.btn-add-meeting {
    @include buttonProperty($greenBlueColor,
        30px,
        auto,
        0,
        14,
        $whiteColor);
    margin-right: 10px;

    &:hover {
        color: $whiteColor;
        box-shadow: none;
        background-color: $greenBlueColor;
    }

    &:focus {
        box-shadow: none;
    }

    h6 {
        font-size: $fz14;
        padding-left: 5px;
    }
}

.table-data-meeting-imgages,
.table-data-meeting-track-user-img {
    @include userImgProperty(35px);
    height: 35px;
    margin-right: 10px;

}

.meet-route-img {
    position: relative;
    width: 25px;
    height: 25px;
    margin: 5px 5px;
}

.meeting-detail-name {
    padding: 2px;
}

.meeting-list-name {
    @include tableDataImgTxt;

    h6 {
        font-size: $tableData_headTextSize;
    }
}

.meeting-track-user-detail {
    margin-left: 10px;
    position: relative;

    h6 {
        font-size: $fz14;
        font-weight: $fwt500;
    }
}

.meeting-action-td {
    button {
        border: none;
        box-shadow: none;
        background-color: transparent;
    }

    .meeting-detail-link,
    .meeting-view-link {
        padding: 2px;
        text-decoration: none;
        color: $greenBlueColor;
    }

    &:hover {
        color: $greenBlueColor;
    }
}

.meeting-details-view,
.meetingdetail-viewroute-link {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: $greenBlueColor;

    &hover {
        background-color: transparent;
        border: none;
        box-shadow: none;
    }
}


.meet-status {
    text-align: center;
    padding: 3px !important;
    font-weight: 500;
    border-radius: 6px;

    &.PCancelled,
    &.ReSchedule {
        color: $whiteColor;
        background-color: $statusOrange;
    }

    &.Pending {
        color: $whiteColor;
        background-color: $statusYellow;
    }
        &.Expired {
            color: $whiteColor;
            background-color: $OrangeRed;
        }

    &.Follow,
    &.Cancelled,
    &.Completed {
        color: $whiteColor;
        background-color: $statusGreen;
    }
}


.meeting-details-list-row-data {
    font-size: $fz13;
    vertical-align: top;
}

.meeting-detail-action-td {
    padding: 2px;
}

.client-meet-status,
.client-meet-purpose-status {
    color: $OrangeRed;
}

.client-meet p,
.client-meet-purpose p {
    padding: 3px 0px;
    margin-bottom: 0px;
    font-size: $fz12;
}

.mtdtls-icon {
    font-size: $fz12;
    font-weight: $fwt600;
    color: $darkgrayColor;
}

.mot-icon-sml {
    width: 20px;
}

.mot-icon {
    width: 25px;
}

.client-meet-location {
    p {
        color: green;
        padding: 3px 0px;
        margin-bottom: 0px;
    }

    i {
        color: rgb(41, 41, 41);
        padding-right: 5px;
    }
}

.td-meeting-details p {
    display: flex;
    padding: 3px 0px;
    margin-bottom: 0px;
}


.track-user-meeting-page, .trakuser-route-map  {
    .row.view-on-map{
    background-color: $whiteColor;
    margin: 0px !important;
    // height: calc(100vh - 210px);
    // height: auto;
    padding-bottom: 10px;
    }
    
}

.track-user-google-map {
    padding: 0;
    .google-map-box {
        width: 100%;
        height: auto;
        padding: 0px;
        text-align: center;
    }
}

.track-user-meeting-page {
    .google-map-box{
        height: auto;
         width: 100%;
    }
}

// .trakuser-route-map  {
//     .google-map-img{
//     width: 100%;
//     height: calc(100vh - 220px);
//     }    
// }

.trak-user-meeting-details-table {
    position: relative;
    background-color: #dddddd;
    height: 385px;
    padding: 8px;
    // width: auto;
    overflow-y: auto;
    overflow-x: hidden;
}

.card.view-on-map-card {
    margin-bottom: 5px;
    width: auto;
    height: fit-content;

    .card-header {
        height: auto;
        padding: 5px;

        p {
            font-size: $fz12;
            position: relative;
        }
    }
}

.card-body.meeting-full-address {
    padding: 5px 10px !important;

    p {
        font-size: $fz12;
        margin-bottom: 0px !important;
    }
}


.view-attach-heading {
    @include modelHeader;

    .cancel-icon {
        float: right;
        position: relative;
        bottom: 45px;
        right: 20px;
        font-size: $fz20;
        color: $greenBlueColor;
        cursor: pointer;
    }
}


.meeting-attachment-image {
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    text-align: center;
    padding: 15px;
}

.meeting-attachment-image img {
    width: 400px;
    height: 200px;
    background-color: $lightGray;
    border-radius: 0px;
}

.attach-print-button {
    @include modelFooter;

    .btn-print {
        background-color: $blackColor;
        height: 35px;
        width: auto;
        border-radius: 0;
        font-size: $fz15;
        color: #ffffff;
        box-shadow: none;
        padding: 5px 15px;
        margin-right: 5px;

        p {
            font-size: $fz14;
            font-weight: $fwt400;
            position: relative;
        }
    }

}

.td-print-column {
    cursor: pointer;
    color: $greenBlueColor;
}

/* SCROLL BAR CSS with Styling START */
/* width */
.meetings-scrollbar,
.track-user-meet-scroll {
    &::-webkit-scrollbar {
        height: 5px;
        width: 5px;
    }
}

/* Track */
.meetings-scrollbar,
.track-user-meet-scroll {
    &::-webkit-scrollbar-track {
        background: $scrollerBackgroundColor;
    }
}

/* Handle */
.meetings-scrollbar,
.track-user-meet-scroll {
    &::-webkit-scrollbar-thumb {
        background: $scrollerThumbColor;
        border-radius: 0px;
    }
}

/* Handle on hover */
.meetings-scrollbar,
.track-user-meet-scroll {
    &:-webkit-scrollbar-thumb:hover {
        background: rgb(102, 102, 102);
    }
}

/* SCROLL BAR CSS with Styling END */