.subscribe-input {
    width: auto !important;
    padding: .3rem .75rem !important;
    background-color:  #fff !important;
}

.menu-div-header {
    padding: 10px 0px;
}

.menu-item-div {
    padding: 10px 0px;
}

/* css for toggle check box */
.checkbox {
    float: right;
    width: 40px;
    height: 1.2rem;
    border-radius: 20px;
    border: 2.5px solid #cbcbcb;
    background: #e2e2e2;
    color: rgba(0, 0, 0, 0.65);
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    /* box-shadow: 9px 9px 16px rgba(189, 189, 189, 0.6),
            -9px -9px 16px rgba(255, 255, 255, 0.5), inset 10px 10px 15px -10px #c3c3c3,
            inset -10px -10px 15px -10px #fff; */
    /* box-shadow: 0px 0.5px 1px 0px rgba(50, 50, 50, 0.72); */
}

.checkbox--on {
    flex-direction: row;
    background: #03a56a36;
    /* box-shadow: 9px 9px 16px rgba(189, 189, 189, 0.6),
            -9px -9px 16px rgba(255, 255, 255, 0.5), inset 10px 10px 15px -10px #c3c3c3,
            inset -10px -10px 15px -10px #fff; */
    /* border: 2.5px solid #4e9e52c1; */
    border: 2.5px solid #03a56a36;
    color: #1D9824;
    flex-direction: row-reverse;

}

#submenu-section-div .checkbox--on {
    background: #ffbb0072;
    border: 2.5px solid #ffbb0072;
}

.checkbox--on .checkbox__ball {
    /* box-shadow: 0px 3px 4px 0px rgb(29, 80, 29); */
    margin-right: 0px;
    /* background: #1D9824; */
    border-radius: 50%;
}

.checkbox--on .checkbox__ball {
    background: #1D9824;
}
#submenu-section-div .checkbox--on .checkbox__ball {
    background: #FFB900;
}

.checkbox__ball {
    /* background: whitesmoke; */
    background: rgb(179, 172, 172);;
    /* box-shadow: 0px 3px 4px 0px rgba(50, 50, 50, 0.72); */
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-left: 0px;

}

.checkbox__text {
    font-size: 1.8rem;
    margin-left: 5px;
    margin-right: 5px;
}

/* end css for checkboc */

.menu-list-row {
    /* padding: 10px; */
    box-shadow: 0px 0px 3px 1px #04a96d47;
    margin: 2% 0px;
    max-height: 160px;
    min-height: 160px;
}

.module-head-div {
    padding: 10px 3px;
    background-color: #fff;
    /* border-bottom: 1px solid #b8efdc; */
    /* box-shadow: 0px 2px 0px #b8efdc; */
}

.sb-hr {
    color: #04a96d47;
    width: 90%;
    box-shadow: 0px 2px 4px #08b777a6;
}

.menu-name {
    font-size: 15px;
    font-weight: 800;
}
.submenu-row {
    margin-bottom: 2%;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.submenu-name {
    font-size: 15px;
    font-weight: 600;
}
.section-sunmenus {
    padding: 14px 0px;
    max-height: 100px;
    min-height: 100px;
    display: flex;
    /* align-items: center; */
    width: 100%;
}
.section-sunmenus::-webkit-scrollbar{
    width: 3px;
    background-color: #c9ecdf;
}
.section-sunmenus::-webkit-scrollbar-thumb{
    background: rgb(29 152 36);
}
.toggle-btn-div {
    display: flex;
    justify-content: flex-end;
}
.scroll {
overflow-y: scroll;
}

.submenus {
    padding-right: 3px;
}

.matrix-div {
    /* box-shadow: 0px 0px 3px 1px #04a96d47; */
    margin: 15px 10px;
    padding: 15px;
}

.input-check {
    /* position: absolute; */
    top: 0;
    left: 0;
}
.checkmark {
    position: absolute;
    top: 0;

}

/* suscription page css */
.sb-subscription-module {
    padding: 20px 10px;
}
.sb-module-card {
    margin: 10px 0px;
    padding: 15px 5px;
    width: 100% !important;
    justify-content: center;
    align-items: center;
    border-radius: 0.4rem;
    border: none !important;
    transition: transform 0.2s;
    box-shadow: rgba(4, 169, 109, 0.133) 0px 5px 20px 0px;
    background: #fff;
    /* box-shadow: rgba(198, 176, 255, 1) 0px 12px 20px, rgba(23, 0, 102, 0.2) 4px 4px 20px, rgba(0, 0, 0, 0.1) 0px 0px 20px; */
}

.sb-module-card:hover {
    transform: scale(1.01);
    box-shadow: rgba(4, 169, 109, 0.318) 0px 5px 20px 0px;
}

.card-body {
    text-align: center;
}

.sb-module-card img {
    width: 60px;
    height: 60px;
}
.sb-card-title {
    color: #5d6064;
    font-weight: 800;
    font-size: 18px;
}

.sb-matrix-div {
    padding: 10px 0px;
}
.subscription-back-btn {
    padding: 5px 10px;
    background-color: #04a96d;
    cursor: pointer;
}
.module-btn-div {
    display: flex;
    justify-content: center;
}

.module-page-header {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid rgba(206, 198, 198, 0.267);
    padding: 5px;
    position: sticky;
    /* top: 0; */
}

input[type=checkbox].css-checkbox {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

input[type=checkbox].css-checkbox+label.css-label {
    padding-left: 20px;
    height: 15px;
    display: inline-block;
    line-height: 15px;
    font-size: 15px;
    vertical-align: middle;
    cursor: pointer;
    background-image: url("../../public/images/subscription/images-check-right.png") !important;
    background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: contain !important;
}

input[type=checkbox].css-checkbox:checked+label.css-label {
    /* background-position: 0 -15px; */
    background-image: url("../../public/images/subscription/images-check-right.png") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: contain !important;
}

/* .css-label {
    background-image: url("../../public/images/subscription/images-check-right.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
} */

.sb-module-footer {
    display: flex;
    flex-direction: row !important;
    justify-content: center;
    align-items: center;
}
.sb-menu-count-devider {
    border-right: 3px solid #ccc;
    margin: 2px 15px;
    height: 20px;
}

.sb-menu-count-icon {
    color: #FFB900;
}

/* dropdown */
#react-select-4-listbox {
    /* position: fixed; */
    display: block;
    z-index: 999 !important;
    overflow: visible;
}
.success-div{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 0px 10px;
}
/* dropdown */
/* suscription page css */