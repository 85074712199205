@import "../styles/variables";
@import "../styles/mixins";

@media screen and (max-width: 1366px){
    .card-dashboard {
        .card-body {
            min-height: 80px !important;
            max-height: 80px !important;
        }
    }
    .user-activity-data {
        min-height: 450px !important;
        max-height: 450px !important;
    }
    .sb-grap-text p, .client-text, .userlist-thead,
    .td-user-location{
        font-size: 12px !important;
    }
    .user-activity-images p, .sb-db-table-th{
        font-size: $fz11 !important;
    }
    .sb-grap-text {
        line-height: 12px !important;
    }
    .act-num {
        font-size: $fz14 !important;
    }
    .card-head {
        font-size: 14px !important;
    }
    .sort-left-dash, .selct-drop-by-dash, .act-heading, .refresh-activity,
    .select-by-dropdown, .sb-db-tbody td, .table-user-image h6 {
        font-size: $fz13 !important;
    }
    .user-list-top-head {
        padding: 8px !important;
    }
    .sch-meeting-head img,
    .direct-visit-head img {
        width: 12px !important;
        height: 12px !important;
    }
    .activity-icons {
        width: 23px !important;
        height: 23px !important;
        .fa,
        .fas {
            font-size: $fz13;
        }
    }
        .sb-card-title {
            font-size: 16px !important;
        }
}
