@import "variables";
@import "mixins";


.login-page-body {
    background: #fff;
    background-image: url("../../src/images/Login_Page/sb-bg.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding:  2% 16%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    // height: 100vh;
    height: 100vh;
    overflow: auto;
    .travelize-salesbee-logo {
        width: 400px;
        margin: 50px auto;
    }
}

@media only screen and (min-width: 1550px) {
    .login-page-body {
        height: 100vh;
    }
    .sb-form-section {
        width: 80%;
    }
    .sb-login-bgimg, .login-box {
        height: 550px;
    }
}

.sb-form-section {
    width: 90%;
}

.sb-login-logo-div {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    padding-bottom: 20px;
}

.sb-login-row {
    background: #fff !important;
    box-shadow: 0px 3px 6px 4px rgb(228 228 228);
    width: 100%;
    padding: 5px 5px !important;
}

.travelize-log, .devider {
    display: flex;
    justify-content: center;
    align-items: center;
}

.devider {
    padding: 5px 0px;
}

.v1 {
    border-left: 3px solid #ededed;
    height: 120px;
}
.sb-trv-logo {
    width: 50%;
}

.col-left {
    padding: 2% 1% 5% 5%;
}
.col-right {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2% 1%;
}

.login-page {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    width: 100%;
    padding: 5px 10px;
    // height: 100%;
}

.sb-login-bgimg {
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 450px;
    width: 100%;
}

.sb-signin-text {
    padding-top: 8%;
}
.sb-forgot-text {
    padding-top: 15%;
}

.login-head-text {
    font-size: $fz25;
    font-weight: $fwt600;
    color: $topbarColor;
    padding: 5px 0px;
    // margin-bottom: 30px;
}

// .login-box {
//     width: 60%;
//     padding: 0px 20px;
//     // height: 100%;
//     height: 450px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     // background: rgba(255, 255, 255, 0.1);
//     border: 2px solid $darkOrangeColor;
//     // box-shadow: 2px 2px 2px $darkOrangeColor;
//     padding: 20px 30px;
//     -moz-border-radius: 6px;
//     -webkit-border-radius: 6px;
//     border-radius: 6px;
//     margin: 10px;
//     text-align: center;

    .form-control {
        font-size: $fz14;
        // border-radius: 4px !important;
        background: #f8f8f8;
        border: 1px solid #E4E4E4 !important;
        padding: 6px 15px;
        color: rgb(0, 0, 0);
        line-height: 20px;
        height: auto;

        &.login-email, &.login-password {
            margin: 20px auto;
            border-radius: 4px !important;
            padding: 10px 15px;
            color: #6c757d;
            border: 2px solid #E4E4E4 !important;
        }

        &:focus {
            box-shadow: 0 0 3px 1px rgb(29, 29, 29);
        }
    }

    .forget-pass-login-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding-top: 20px;
    }

    .forgot-pass-text {
        text-decoration: none;
        font-size: $fz14;
        color: #0a58ca;
        font-weight: 800;

        &:focus {
            text-decoration: none;
            box-shadow: none;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .btn-login-button {
        background-color: $greenBlueColor;
        border-radius: 0px;
        font-size: $fz15;
        font-weight: 800;
        padding: 5px 10px;
        color: $whiteColor;
        margin-bottom: 0px;
        // min-width: 150px;
        min-width: 100%;
        border: none;

        &:hover {
            color: $whiteColor;
            background-color: $darkOrangeColor;
        }
        &:focus {
            background-color: $greenBlueColor ;
        }
        &:focus{
            box-shadow: none;
        }
    }
// }

.pass-hide-show {
    float: right;
    position: absolute;
    top: 12px;
    right: 10px;
    font-size: 17px;
    color: #7e8388;
    cursor: pointer;
}

.password-input {
    display: block;
    position: relative;
}


.login-msg {
    font-size: $fz14;

    &.success {
        color: rgb(53, 199, 0);
    }

    &.false {
        color: red;
    }
}


.subscription-page-body{
    text-align: center;
    align-items: center;
    background: $mainBody_backgroundImg left top repeat #1b1e24;
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: auto;
    .salesbeebasiclogo {
        width: 20%;
        margin: 30px auto;
    }
}

.subscription-page {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;

    .subscription-box {
        width: 30%;
        background: rgba(255, 255, 255, 0.1);
        padding: 20px;
        -moz-border-radius: 0px;
        -webkit-border-radius: 0px;
        border-radius: 0px;
        margin: 10px;
        text-align: center;

        .login-head-text {
            font-size: $fz25;
            font-weight: $fwt600;
            font-family: Cambria, serif;
            color: $whiteColor;
            padding: 5px;
        }

        .sub-inputs {
            margin: 10px auto !important;
        }

        .contact-input {
            display: flex;
        }

        .form-control {
            font-size: $fz14;
            border: 0px;
            background: rgba(0, 0, 0, 0.2);
            padding: 10px 15px;
            color: rgb(235, 235, 235);
            line-height: 20px;
            height: auto;

            &:focus {
                box-shadow: 0 0 3px 1px rgb(29, 29, 29);
            }
        }

        .form-select {
            display: block;
            padding: 0.375rem 2.25rem 0.375rem 0.75rem;
            -moz-padding-start: calc(0.75rem - 3px);
            font-size: $fz14;
            font-weight: 400;
            line-height: 1.5;
            color: $whiteColor;
            background-color: rgba(0, 0, 0, 0.2);
            background-image: url(../images/icon-select.png);
            background-repeat: no-repeat;
            background-position: right 0.75rem center;
            background-size: 16px 12px;
            border: none;
            border-radius: 0.25rem;
            transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            &.cntry-code {
                width: 30%;
                margin-right: 10px;

                &:focus {
                    box-shadow: none !important;
                }
            }
        }

        .btn-submit-button {
            background-color: $greenBlueColor;
            border: none;
            border-radius: 5px;
            font-size: $fz14;
            padding: 10px 10px;
            color: $whiteColor;
            margin: 10px auto;
            width: 100%;

            &:hover {
                color: $whiteColor;
                background-color: $greenBlueColor;
                box-shadow: none;
            }
        }

        .subscription-res-message {
            .alert {
                padding: 0.5rem 0.5rem;
                font-size: $fz14;
                font-weight: $fwt400;
                text-transform: capitalize;
                border: none;
                margin-bottom: 0px;
                opacity: 1;
                animation-name: visibility;
                animation-duration: 10s;
            }

            @keyframes visibility {
                from {
                    opacity: 1;
                }

                to {
                    opacity: 0;
                }
            }
        }
    }
}

@media (max-width: 576px) {
    .login-box {
        width: auto !important;
        display: block;
        align-items: center;
    }
    .travelize-salesbee-logo {
        width: 60% !important;
        margin: 30px auto;
    }

    .subscription-box {
        width: auto !important;
        display: block;
        align-items: center;
    }
    .salesbeebasiclogo {
        width: 60% !important;
        margin: 30px auto;
    }

}