@import "variables";
@import "mixins";

.account-table .account-page-title {
    font-size: $fz16 !important;
    font-weight: $fwt700 !important;
    margin-left: 10px !important;
    flex: auto;
}

.subscription-card {
    position: relative;
    border: none;
    border-radius: 0px;
    width: 350px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 10px 3px #c0bfbf;
}

.account-subscription-data-row {
    background-color: $whiteColor;
    height: calc(100vh - 128px);
    padding: 25px;
    overflow: hidden;
}

.current-subscription-part {
    position: relative;
    margin-left: 30px;
    margin-top: 10px;
    .current-subscription {
        font-size: $fz22;
        color: $darkOrangeColor;
        text-align: center;
        padding: 10px;
        border-bottom: 2px solid $lightGray;
    }
    .curr-sub-details {
        display: flex;
        margin: 20px 10px;
    }
}

.subscription-details-head {
    margin-left: 5px;
    font-size: $fz18;
    float: left;
    flex: auto;
    color: $greenBlueColor;
}

.subscription-details-data {
    font-size: $fz18;
    color: $darkgrayColor;

}

.subscribe-now-button {
    align-items: center;
    text-align: center;
    .btn-subscribe-now {
        background-color: $blackColor;
        color: $whiteColor;
        font-weight: $fwt600;
        width: 100%;
        border-radius: 0px;
    }
}
