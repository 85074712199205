@import "variables";
@import "mixins";

.adddropdowndata-scrollbar {
    height: auto;
    margin: 15px;
    overflow: hidden;
}

.add-state-cities{
    position: relative;
    max-height: 70vh;
    overflow-x: hidden;
}

.add-country,
.add-state {
    border-bottom: 2px solid $lightGray;
}

.add-country-state-city-part {
    margin: 10px;
    padding: 5px 10px;
    box-shadow: 0 0 2px 1px $lightGray;

    .add-country,
    .add-state,
    .add-city {
        padding: 15px 12px 15px 12px;

        h6 {
            text-align: center;
            margin-bottom: 10px;
        }

        .add-state-input {
            font-size: $fz13;
        }

        .btn-update-zone {
            width: 100%;
            background-color: $greenBlueColor;
            color: $whiteColor;
        }
    }
}

.table-height{
    height: calc(100vh - 250px);
    overflow-x: hidden;
}

.country-table, .state-table, .city-table{
    text-align: center;
    .top-sticky{
        position: sticky;
        top: 0;
    }
    .country-head{
        padding: 5px 8px;
        background-color: $tableHead_backgroundColor;
        color: $tableHead_textColor;
        font-weight: $fwt700;
        
    }
    .country-list{
        text-align: left;
        padding: 5px 10px;
        border: 1px solid $lightGray;
    }
}